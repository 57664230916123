import React from "react";
import { ScrollButton } from "./ScrollToTopButton.styles";

const ScrollToTopButton = () => {
  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <ScrollButton onClick={handleClick}>Voltar ao topo</ScrollButton>
    </>
  );
};

export default ScrollToTopButton;
