import styled from "styled-components";
import { UIColors } from "../../styles/UIColors";
import { Theme } from "../../theme/Theme";

export const Background = styled.section`
  background-color: ${UIColors.VERMELHO_ESCURO};
`;

export const Container = styled.div`
  display: flex;
  max-width: ${Theme.SPACING.CONTAINER}px;
  height: auto;
  width: 100%;
  margin: 0 auto;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  position: relative;
  flex: 1;
  @media screen and (max-width: 600px) {
    flex-direction: column;
    margin-bottom: 4em;
    margin-top: 4em;
    padding-right: 15px;
    padding-left: 15px;
    justify-content: unset;
  }
`;

export const Content = styled.div`
  width: 50%;
  padding-top: 25px;
  padding-bottom: 40px;
  @media screen and (max-width: 767px) {
    width: 100% !important;
  }
`;

export const Caption = styled.h3`
  color: ${UIColors.BEGE_CLARO};
`;

export const Title = styled.h1`
  font-size: 48px;
  margin-top: 20px;
  color: #fff;
  strong {
    color: ${UIColors.BEGE_CLARO};
  }
  @media screen and (max-width: 600px) {
    font-size: 40px;
  }
`;

export const Description = styled.h3`
  color: #fff;
  margin-top: 20px;
  font-family: "Open Sans", sans-serif;
  font-weight: normal;
  line-height: 1.5;
  font-size: 14px;
`;

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-left: 50px;
  @media screen and (max-width: 600px) {
    margin-left: 0px;
  }
`;
export const Box = styled.div``;

export const TextField = styled.input`
  padding: 15px;
  width: 100%;
  border-radius: 5px;
`;

export const Button = styled.button`
  width: 100%;
  padding: 20px;
  text-transform: uppercase;
  border-radius: 5px;
  background-color: ${UIColors.VERMELHO_ESCURO};
  color: #fff;
  font-weight: bold;

  cursor: pointer;

  -webkit-box-shadow: 15px 8px 86px -19px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 15px 8px 86px -19px rgba(0, 0, 0, 0.75);
  box-shadow: 15px 8px 86px -19px rgba(0, 0, 0, 0.75);

  &:hover {
    background-color: #a7a290;
  }
  &:active {
    background-color: #838075;
  }
`;

export const Form = styled.form`
  position: relative;
  z-index: 1;
  background: #fff;
  max-width: 500px;
  margin: 0 auto 100px;
  padding: 45px;
  border-radius: 18px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
`;

export const TitleForm = styled.h2`
  font-size: 18px;
  text-align: center;
  color: ${UIColors.VERMELHO_ESCURO};
  margin-bottom: 30px;
`;

export const Label = styled.label`
  color: ${UIColors.VERMELHO_ESCURO};
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
`;

export const Image = styled.img`
  width: 100%;
`;
