import * as React from "react";
import { Label, TextInput } from "./TextField.styles";

interface TextFieldProps {
  label: string;
  name: string;
}

const TextField = ({ label, name, ...props }: TextFieldProps) => {
  return (
    <>
      <Label>
        <span>{label}</span>
        <TextInput id={name} name={name} {...props} />
      </Label>
    </>
  );
};

export default TextField;
