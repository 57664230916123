import styled from "styled-components";

export const ScrollButton = styled.button`
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 15px;
  background-color: #696451;
  color: white;
  border: none;
  border-radius: 200px;
  font-size: 14px;
  cursor: pointer;
`;
