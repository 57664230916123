import styled from "styled-components";
import { UIColors } from "../../../styles/UIColors";

export const Label = styled.label`
  color: ${UIColors.VERMELHO_ESCURO};
  font-family: "Open Sans", sans-serif;
  font-weight: bold;

  span {
    line-height: 30px;
    margin-bottom: 20px;
  }
`;
export const SelectInput = styled.select`
  font-family: "Open Sans", sans-serif;
  outline: 0;
  background: #f2f2f2;
  width: 100%;
  border: 0;
  margin: 0 0 15px;
  padding: 15px;
  box-sizing: border-box;
  font-size: 14px;
`;
