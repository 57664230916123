import styled from "styled-components";
import { UIColors } from "../../styles/UIColors";
import { Theme } from "../../theme/Theme";

export const Background = styled.div`
  background-color: #b5ac8d;
  position: fixed;
  top: 0;
  z-index: 9;
  width: 100%;
  padding: 10px;
`;

export const ContainerHeader = styled.div`
  display: flex;
  max-width: ${Theme.SPACING.CONTAINER}px;

  width: 100%;
  margin: 0 auto;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  position: relative;
  flex: 1;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    margin-bottom: 4em;
    margin-top: 4em;
    padding-right: 15px;
    padding-left: 15px;
    justify-content: unset;
  }
`;

export const Logo = styled.img`
  max-width: 225px;
  max-height: 45px;
`;

export const MenuContainer = styled.nav`
  /* margin-left: 5rem; */
  > ul {
    display: flex;
    li {
      font-size: 20px;
      &:before {
        content: "•";
        margin-right: 0.6rem;
        color: ${UIColors.VERMELHO_ESCURO};
      }
      margin-right: 2rem;
      a {
        color: ${UIColors.BEGE_ESCURO};
      }
      a:hover,
      a:focus {
        color: ${UIColors.VERMELHO_ESCURO};

        outline: none;
      }
    }
  }

  @media screen and (max-width: 992px) {
    > ul {
      margin: 0.7rem 15px;
    }
  }
`;
