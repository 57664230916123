import * as React from "react";
import { UIColors } from "../../styles/UIColors";
import {
  Background,
  Card,
  Content,
  Description,
  Section,
  Title,
} from "./About.styles";

import FormacaoIcon from "../../assets/images/about/formacao.png";
import ReconhecimentoIcon from "../../assets/images/about/reconhecimento.png";
import RemdimentoIcon from "../../assets/images/about/rendimento.png";
import BandeiraIcon from "../../assets/images/about/bandeira.png";

const About = () => {
  return (
    <Background id="about">
      <Section>
        <Content
          className="left"
          style={{ backgroundColor: UIColors.VERMELHO_ESCURO }}
        >
          <div className="content">
            <div className="title">
              <Title>
                Quem <br />
                <strong>somos</strong>
              </Title>
            </div>
            <div>
              <Description
                style={{ color: "white", marginTop: 45, width: "80%" }}
              >
                Somos uma empresa que olha para o ser humano e acredita no seu
                potencial profissional e valorização do seu trabalho.
                <br />
                <br />
                A IMMEE foi fundada por um grupo de profissionais de distintas
                áreas com senioridade em captação, seleção, preparação,
                legalização e suporte para levar enfermeiras brasileiras para os
                EUA com toda a segurança e transparência que um passo de tamanha
                relevância na vida deve ser tratado.
                <br />
                <br />
                Nossa proposta é cuidar do processo de ponta a ponta, desde a
                seleção dos profissionais que irão aplicar para trabalhar nos
                EUA, passando pelo contrato de trabalho garantido em hospitais
                americanos, até a escolha do melhor banco para abrir sua conta
                no exterior.
                <br />
                <br />A IMMEE cuida de tudo e com o foco na pessoa envolvida no
                processo, de forma a criar um caminho suave e seguro em direção
                à valorização do profissional enfermeiro que atuará nos EUA com
                o nosso patrocínio.
              </Description>
            </div>
          </div>
        </Content>
        <Content
          className="right"
          style={{ backgroundColor: UIColors.BEGE_CLARO }}
        >
          <div className="content">
            <div className="title">
              <Title>
                Quali
                <br />
                <strong>ficações:</strong>
              </Title>
            </div>
            <div className="cardContent">
              <Card>
                <div className="cardIcon">
                  <img src={FormacaoIcon} alt="icon" />
                </div>
                <div className="cardTitle">Diretores</div>
                <div
                  className="cardDescription"
                  style={{ float: "left", marginTop: "25px" }}
                >
                  Nossos diretores são especialistas em ajudar profissionais de
                  saúde a realizarem o sonho de trabalhar nos Estados Unidos.
                </div>
              </Card>

              <Card>
                <div className="cardIcon">
                  <img src={ReconhecimentoIcon} alt="icon" />
                </div>
                <div className="cardTitle">Credibilidade</div>
                <div className="cardDescription">
                  Com um histórico comprovado de sucesso em enviar profissionais
                  de saúde altamente qualificados para os Estados Unidos.
                </div>
              </Card>

              <Card>
                <div className="cardIcon">
                  <img src={RemdimentoIcon} alt="icon" />
                </div>
                <div className="cardTitle">Experiência</div>
                <div className="cardDescription">
                  Com anos de experiência e uma equipe dedicada de especialistas
                  em imigração e saúde.
                </div>
              </Card>
            </div>
          </div>

          <div className="bandeira">
            <img src={BandeiraIcon} alt="" />
          </div>
        </Content>
      </Section>
    </Background>
  );
};

export default About;
