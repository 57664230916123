import * as React from "react";
import {
  Background,
  Button,
  Container,
  Content,
  Description,
  Image,
  Title,
} from "./WhySelect.styles";

const WhySelect = () => {
  return (
    <Background>
      <Container style={{ marginTop: 100 }}>
        <Content>
          <Image src="/img/why_select.jpg" alt="image" />
        </Content>
        <Content style={{ paddingLeft: 50 }}>
          <Title>
            Por que devo <br />
            escolher a{" "}
            <strong style={{ fontFamily: "Aller Bold, sans-serif" }}>
              IMMEE?
            </strong>
          </Title>
          <Description>
            A principal razão para escolher a IMME é a garantia de um trabalho
            com todos os <strong>esforços voltados para você!</strong>
            <br />
            <br />
            Disso podemos derivar muitos detalhes da nossa operação, mas que se
            resume bem ao nos comparar com uma ponte perfeitamente asfaltada e
            segura que te conecta com a valorização do seu trabalho nos Estados
            Unidos da América residência permanente.
          </Description>
          <a href="#signup">
            <Button>Candidatar</Button>
          </a>
        </Content>
      </Container>
    </Background>
  );
};

export default WhySelect;
