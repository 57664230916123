import styled from "styled-components";
import { UIColors } from "../../styles/UIColors";
import { Theme } from "../../theme/Theme";

type ContainerProps = {
  toLeft?: Boolean;
};

export const Container = styled.section`
  display: flex;
  max-width: ${Theme.SPACING.CONTAINER}px;
  height: auto;
  width: 100%;
  margin: 0 auto;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  position: relative;
  flex: 1;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    margin-bottom: 4em;
    margin-top: 4em;
    padding-right: 15px;
    padding-left: 15px;
    justify-content: unset;
  }
`;

export const ImageWork = styled.img`
  width: 110%;
`;

export const ContainerGrid = styled.div`
  display: grid;
  grid-column-gap: px;
  grid-template-columns: auto auto;
  width: 50%;

  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Content = styled.div`
  text-align: right;
  display: flex;
  justify-content: end;
  flex-direction: column;
`;

export const Title = styled.h1`
  text-align: right;
  font-family: "Aller", sans-serif;
  font-size: 48px;
  text-transform: uppercase;
  font-weight: 600;
  color: ${UIColors.BEGE_ESCURO};
  margin-top: 40px;
  strong {
    color: ${UIColors.VERMELHO_ESCURO};
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 40px;
`;

export const TitleCard = styled.h2`
  font-family: "Aller", sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  color: ${UIColors.BEGE_ESCURO};
`;

export const DescriptionCard = styled.p`
  text-align: right;
  width: 75%;
  margin-top: 10px;
  font-family: "Open Sans", sans-serif;
  color: ${UIColors.BEGE_ESCURO};
  line-height: 1.5;
  font-size: 14px;
  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;
