import React from "react";
import { FaLinkedin } from "react-icons/fa";
import {
  Container,
  Content,
  Grid,
  Image,
  Title,
  UserName,
  UserWork,
  UserContainer,
  Button,
  ImageContainer,
} from "./Board.styles";

const Board = () => {
  const items = [
    {
      id: 1,
      name: " Italo Marsili",
      image: "Italo_Marsili_CEO.png",
      office: "CEO",
      linkdin: "https://www.linkedin.com/in/italo-marsili-7501a048/",
    },
    {
      id: 2,
      name: "Marcos de Grande",
      image: "marcus_de_grande.png",
      office: "COO",
      linkdin: "https://www.linkedin.com/in/marcus-de-grandis-a0654314a/",
    },

    {
      id: 3,
      name: " Marcelo Godke",
      image: "marcelo_godke.png",
      office: "Diretor Jurídico Geral",
      linkdin: "https://www.linkedin.com/in/marcelo-godke/",
    },
    {
      id: 4,
      name: "Jefrey P Marathas Esq",
      image: "jefrey_marathas.png",
      office: "Diretor Jurídico EUA",
      linkdin: "https://www.linkedin.com/in/jeffrey-p-marathas-esq-38216661/",
    },
    {
      id: 5,
      name: " Fernando Canutto",
      image: "fernando.png",
      office: "Diretor Jurídico Brasil",
      linkdin: "https://www.linkedin.com/in/fernando-canutto-bab8914/",
    },
    {
      id: 6,
      name: "Ana de Gregório",
      image: "ana_de_gregorio.png",
      office: "Diretor Jurídico Portugal",
      linkdin: "https://www.linkedin.com/in/ana-de-gregorio-3968a577/",
    },
    {
      id: 8,
      name: "Priscila Salgado",
      image: "priscila_salgado.png",
      office: "Diretor RH",
      linkdin: "https://www.linkedin.com/in/salgadopriscila/",
    },
    {
      id: 9,
      name: " Alexander Coelho",
      image: "alexander_coelho.png",
      office: "DPO",
      linkdin: "https://www.linkedin.com/in/alexandercoelho/",
    },
    {
      id: 10,
      name: "Heman Molina",
      image: "heman_molina.png",
      office: "Diretor Tecnologia",
      linkdin: "https://www.linkedin.com/in/heman-molina-a27627/",
    },
    {
      id: 11,
      name: " Beatriz Godke",
      image: "beatriz_godke.png",
      office: "Diretor Educacional",
      linkdin:
        "https://www.linkedin.com/in/beatriz-azevedo-dos-anjos-godke-veiga-227a3525/",
    },
    {
      id: 12,
      name: "Anna Maria",
      image: "anna_maria.png",
      office: "Conselheira",
      linkdin: "https://www.linkedin.com/in/anna-maria-godke/",
    },
  ];

  const navigate = (url: string) => {
    window.location.href = url;
  };

  return (
    <Container id="board">
      <div style={{ width: "100%", marginBottom: 50, textAlign: "center" }}>
        <Title>Nossa Diretoria</Title>
      </div>

      <div
        style={{
          display: "flex",
          gap: "55px",
          flexWrap: "wrap",
          paddingBottom: "30px",
          justifyContent: "center",
        }}
      >
        {items.map((item) => (
          <Grid>
            <Content>
              <ImageContainer>
                <Image src={`/img/diretoria/${item.image}`} alt={item.name} />
              </ImageContainer>
              <UserContainer>
                <UserName>{item.name}</UserName>
                <UserWork>{item.office}</UserWork>
                <Button onClick={() => navigate(item.linkdin)}>
                  <div style={{ marginRight: "5px" }}>
                    <FaLinkedin />
                  </div>
                  <div style={{ marginTop: "-3px" }}>LinkdIn</div>
                </Button>
              </UserContainer>
            </Content>
          </Grid>
        ))}
      </div>
    </Container>
  );
};

export default Board;
