import styled from "styled-components";
import banner from "../../assets/img/banner_background.jpg";
import { UIColors } from "../../styles/UIColors";
import { Theme } from "../../theme/Theme";

export const Background = styled.div`
  background-image: url(${banner});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

export const Container = styled.section`
  display: flex;
  max-width: ${Theme.SPACING.CONTAINER}px;
  height: 675px;
  width: 100%;
  margin: 0 auto;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  position: relative;
  flex: 1;
  min-height: 675px;

  .slick-slider {
    width: 100%;
    min-height: 675px;
  }
  .slick-slide {
    min-height: 675px;
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
    padding-right: 15px;
    padding-left: 15px;
    justify-content: unset;
    min-height: 600px;
  }
`;

export const ContainerHeader = styled.div`
  display: flex;
  max-width: ${Theme.SPACING.CONTAINER}px;
  width: 100%;
  margin: 0 auto;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  position: relative;
  flex: 1;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    padding-right: 15px;
    padding-left: 15px;
    justify-content: unset;
  }
`;

export const SlideContainer = styled.div`
  width: 100%;
`;

export const SlideContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const SlideGrid = styled.div`
  z-index: 2;
`;

export const SlideTextContainer = styled.div`
  margin-top: 8rem;
  width: 65%;

  @media screen and (max-width: 767px) {
    margin-top: 2rem;
  }
`;

export const SlideTitle = styled.h1`
  font-size: 36px;
  text-transform: uppercase;
  color: #696451;
  font-family: "Aller Bold", sans-serif;
  font-weight: 800;
  span {
    color: white;
  }
`;

export const SlideDescription = styled.div`
  margin-top: 2rem;
`;

export const SlideDescriptionItem = styled.p`
  line-height: 1.5rem;
  font-size: 18px;
  margin-bottom: 1rem;
  color: #696451;
  &:before {
    content: "•";
    margin-right: 0.6rem;
    color: ${UIColors.VERMELHO_ESCURO};
  }
`;

export const Logo = styled.img`
  max-width: 225px;
  max-height: 45px;
`;

export const SlideImageContainer = styled.div`
  position: absolute;
  bottom: 0;
  z-index: 1;
`;

export const SlideImage = styled.img`
  width: 75%;
  margin-left: -10%;
  z-index: 1;
`;

export const ButtonContainer = styled.div`
  margin-top: 3rem;
  z-index: 2;
`;

export const Button = styled.button`
  text-transform: uppercase;
  padding: 15px 30px;
  font-size: 14px;
  border-radius: 100px;
  color: white;
  font-weight: 400;
  letter-spacing: 2px;
  background-color: ${UIColors.VERMELHO_ESCURO};
  cursor: pointer;

  -webkit-box-shadow: 15px 8px 86px -19px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 15px 8px 86px -19px rgba(0, 0, 0, 0.75);
  box-shadow: 15px 8px 86px -19px rgba(0, 0, 0, 0.75);

  &:hover {
    background-color: ${UIColors.VERMELHO_HOVER};
  }
  &:active {
    background-color: ${UIColors.VERMELHO_FOCUS};
  }
`;

export const Menu = styled.nav`
  /* margin-left: 5rem; */
  > ul {
    display: flex;
    li {
      font-size: 20px;
      &:before {
        content: "•";
        margin-right: 0.6rem;
        color: ${UIColors.VERMELHO_ESCURO};
      }
      margin-right: 2rem;
      a {
        color: ${UIColors.BEGE_ESCURO};
      }
      a:hover,
      a:focus {
        color: ${UIColors.VERMELHO_ESCURO};

        outline: none;
      }
    }
  }

  @media screen and (max-width: 767px) {
    > ul {
      display: none;
    }
  }
`;
