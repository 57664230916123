import styled from "styled-components";
import { UIColors } from "../../styles/UIColors";
import { Theme } from "../../theme/Theme";

export const Container = styled.section`
  display: flex;
  max-width: ${Theme.SPACING.CONTAINER}px;
  width: 100%;
  margin: 0 auto;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  position: relative;
  flex: 1;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    margin-bottom: 1em;
    margin-top: 1em;
    padding-right: 15px;
    padding-left: 15px;
    justify-content: unset;
  }
`;

export const Background = styled.div`
  background-color: ${UIColors.BEGE_CLARO};
  padding-top: 10px;
  padding-bottom: 10px;
`;

export const HeaderStyled = styled.header`
  background-color: ${UIColors.BEGE_CLARO};
  padding: 15px;
  text-align: left;
  font-weight: 100;
  width: "100%";
  h3 {
    font-family: "Aller";
    font-weight: normal;
  }
`;


export const Button = styled.button`
  text-transform: uppercase;
  padding: 10px 20px;
  font-size: 12px;
  border-radius: 100px;
  color: white;
  font-weight: 400;
  letter-spacing: 2px;
  background-color: ${UIColors.BEGE_ESCURO};
  cursor: pointer;

  -webkit-box-shadow: 15px 8px 86px -19px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 15px 8px 86px -19px rgba(0, 0, 0, 0.75);
  box-shadow: 15px 8px 86px -19px rgba(0, 0, 0, 0.75);

  &:hover {
    background-color: ${UIColors.VERMELHO_HOVER};
  }
  &:active {
    background-color: ${UIColors.VERMELHO_FOCUS};
  }
`;


export const Menu = styled.nav`
  > ul {
    display: flex;

    li {
      margin-right: .3rem;

      a {
        color:  ${UIColors.BEGE_ESCURO};
      }

      a:hover,
      a:focus {
        color:  ${UIColors.BEGE_ESCURO};
        outline: none;
      }
    }
  }

  @media screen and (max-width: 768px) {
    > ul {
    display: flex;

    li {
      margin-right: .3rem;

      a {
        color:  ${UIColors.BEGE_ESCURO};
      }

      a:hover,
      a:focus {
        color:  ${UIColors.BEGE_ESCURO};
        outline: none;
      }
    }
  } 
  }
`;
