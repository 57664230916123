import styled from "styled-components";
import { UIColors } from "../../styles/UIColors";
import { Theme } from "../../theme/Theme";

export const Background = styled.div`
  background: linear-gradient(
    to right,
    ${UIColors.VERMELHO_ESCURO} 0%,
    ${UIColors.VERMELHO_ESCURO} 50%,
    ${UIColors.BEGE_CLARO} 50%,
    ${UIColors.BEGE_CLARO} 100%
  );

  @media screen and (max-width: 600px) {
    background: linear-gradient(
      to top,
      ${UIColors.BEGE_CLARO} 0%,
      ${UIColors.BEGE_CLARO} 50%,
      ${UIColors.VERMELHO_ESCURO} 50%,
      ${UIColors.VERMELHO_ESCURO} 100%
    );
  }
`;

export const Title = styled.h1`
  font-size: 4rem;
`;

export const Section = styled.section`
  display: flex;
  max-width: ${Theme.SPACING.CONTAINER}px;
  height: auto;
  width: 100%;
  margin: 0 auto;
  flex-direction: row;
  position: relative;
  flex: 1;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    margin-bottom: 4em;
    margin-top: 4em;
    justify-content: unset;
  }

  .content {
    display: flex;
    flex-direction: column;
      @media screen and (max-width: 600px) {
        padding-right: 1rem;
        padding-left: 1rem;
     }
  }

  .cardContent {
    display: flex;
    margin-top: 25px;
    gap: 15px;
  }
  .left {
    padding-bottom: 160px;
    padding-top: 5rem;
    &:before {
      content: "";
    }
    h1 {
      color: white;
    }
    strong {
      color: ${UIColors.BEGE_CLARO};
    }
  }
  .right {
    padding-bottom: 160px;
    padding-top: 5rem;

    &::before {
      content: "";
    }
    h1 {
      color: ${UIColors.BEGE_ESCURO};
    }
    strong {
      color: ${UIColors.VERMELHO_ESCURO};
    }
  }

  .bandeira {
    position: absolute;
    margin-left: -11.5rem;
    margin-top: 6.5rem;
  }

  @media screen and (max-width: 600px) {
    flex-direction: column;
    .left {
      padding-left: 0px;
    }
    .right {
      padding-left: 0px;
      padding-right: 0px;
    }
    .bandeira {
      margin-left: 5rem;
      margin-top: 3rem;
    }
  }

  .title {
    font-family: "Aller";
    font-size: 48px;
    text-transform: uppercase;
    font-weight: 400;

    h1 {
      font-family: "Aller";
    }

    @media screen and (max-width: 600px) {
      font-size: 48px;
    }
  }

  strong {
    font-weight: bold;
  }
`;

export const Container = styled.div``;

export const Content = styled.div`
  width: 50%;
  padding: 20px;
  @media screen and (max-width: 600px) {
    width: 100%;
    padding: 0px;
  }
`;

export const Description = styled.p`
  font-size: 14px;
  font-weight: 100;

  font-family: "Open Sans";
  line-height: 1.4;
`;

export const Card = styled.div`
  background-color: transparent;
  width: 170px;
  margin-top: 2rem;
  .cardIcon {
    width: 80px;
    height: 65px;

  }
  .cardTitle {
    margin-top: 15px;
    text-transform: uppercase;
    color: ${UIColors.BEGE_ESCURO};
    font-family: "Oswald", sans-serif;
    font-size: 16px;
  }
  .cardDescription {
    margin-top: 25px;
    color: ${UIColors.MARROM_ESCURO};
    font-family: "Open Sans", sans-serif;
    font-style: italic;
    font-size: 14px;
    font-weight: 400;

  }
`;
