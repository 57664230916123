import React from "react";
import {
  Background,
  Button,
  ButtonContainer,
  Container,
  ContainerHeader,
  Logo,
  Menu,
  SlideContainer,
  SlideContent,
  SlideDescription,
  SlideDescriptionItem,
  SlideGrid,
  SlideImage,
  SlideImageContainer,
  SlideTextContainer,
  SlideTitle,
} from "./Hero.styles";
import Slider, { Settings } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Banner1 from "../../assets/img/banner/person1.png";
import Banner2 from "../../assets/img/banner/person2.png";
import LogoImg from "../../assets/images/logo/logo.svg";
import useMobile from "../../hooks/useMobile";

interface SliderProps {
  items: {
    imageUrl: string;
    title: string;
    description: string;
  }[];
}

const Hero = () => {
  const isMobile = useMobile();
  const settings: Settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
  };

  return (
    <Background id="home">
      <ContainerHeader style={{ marginTop: "5rem" }}>
        <div>
          <Logo src={LogoImg} alt="Image logo" />
        </div>
        <Menu>
          <ul>
            <li>
              <a href="#home">Início</a>
            </li>
            <li>
              <a href="#about">Quem somos</a>
            </li>
            <li>
              <a href="#howToWork">Como funciona?</a>
            </li>
            <li>
              <a href="#signup">Candidatar-se</a>
            </li>
          </ul>
        </Menu>
      </ContainerHeader>
      <Container>
        <Slider {...settings}>
          <SlideContainer>
            <SlideContent>
              <SlideGrid>
                <SlideTextContainer style={{ width: "100%" }}>
                  <SlideTitle style={{ fontSize: "48px" }}>
                    Nossos <br />
                    Serviços <br />
                    <span>Gratuitos</span>
                  </SlideTitle>
                  <SlideDescription>
                    <SlideDescriptionItem style={{ marginBottom: ".5rem" }}>
                      Curso Inglês para enfermeiras.
                    </SlideDescriptionItem>
                    <SlideDescriptionItem style={{ marginBottom: ".5rem" }}>
                      Validação do Diploma e NCLEX.
                    </SlideDescriptionItem>
                    <SlideDescriptionItem style={{ marginBottom: ".5rem" }}>
                      Visto americano permanente (Green Card).
                    </SlideDescriptionItem>
                    <SlideDescriptionItem style={{ marginBottom: ".5rem" }}>
                      Contrato de trabalho em Hospitais nos EUA.
                    </SlideDescriptionItem>
                    <SlideDescriptionItem style={{ marginBottom: ".5rem" }}>
                      Assessoria no processo de imigração.
                    </SlideDescriptionItem>
                    <SlideDescriptionItem style={{ marginBottom: ".5rem" }}>
                      Suporte integral pós-imigração.
                    </SlideDescriptionItem>
                  </SlideDescription>
                  <ButtonContainer>
                    <a href="#signup">
                      <Button draggable={true}>Candidate-se</Button>
                    </a>
                  </ButtonContainer>
                </SlideTextContainer>
              </SlideGrid>
              {!isMobile && (
                <SlideImageContainer>
                  <SlideImage src={Banner2} alt="Banner 1" />
                </SlideImageContainer>
              )}
            </SlideContent>
          </SlideContainer>
          <SlideContainer>
            <SlideContent>
              <SlideGrid>
                <SlideTextContainer>
                  <SlideTitle>
                    Para alcançar <br />
                    seus sonhos <br />
                    <span>
                      nós seremos seus <br />
                      patrocinadores
                      <br /> e parceiros
                    </span>
                  </SlideTitle>
                  <SlideDescription>
                    <SlideDescriptionItem>
                      Existe um lugar onde sua profissão é realmente valorizada.
                    </SlideDescriptionItem>
                    <SlideDescriptionItem>
                      Ajudamos enfermeiros brasileiros a realizarem seu sonho
                      americano.
                    </SlideDescriptionItem>
                  </SlideDescription>
                  <ButtonContainer>
                    <a href="#signup">
                      <Button draggable={true}>Candidate-se</Button>
                    </a>
                  </ButtonContainer>
                </SlideTextContainer>
              </SlideGrid>

              {!isMobile && (
                <SlideImageContainer>
                  <SlideImage src={Banner1} alt="Banner 1" />
                </SlideImageContainer>
              )}
            </SlideContent>
          </SlideContainer>
        </Slider>
      </Container>
    </Background>
  );
};

export default Hero;
