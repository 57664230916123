import React from "react";
import {
  Background,
  ContainerHeader,
  Logo,
  MenuContainer,
} from "./Menu.styles";
import LogoImg from "../../assets/images/logo/logo.svg";

const Menu = () => {
  return (
    <Background>
      <ContainerHeader>
        <div>
          <Logo src={LogoImg} alt="Image logo" />
        </div>
        <MenuContainer>
          <ul>
            <li>
              <a href="#home">Início</a>
            </li>
            <li>
              <a href="#about">Quem somos</a>
            </li>
            <li>
              <a href="#howToWork">Como funciona?</a>
            </li>
            <li>
              <a href="#signup">Candidatar-se</a>
            </li>
          </ul>
        </MenuContainer>
      </ContainerHeader>
    </Background>
  );
};

export default Menu;
