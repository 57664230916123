import * as React from "react";
import SelectField from "../../components/Inputs/SelectField/SelectField";
import TextField from "../../components/Inputs/TextField/TextField";
import {
  Background,
  Button,
  Caption,
  Container,
  Content,
  Description,
  Form,
  Image,
  Label,
  Title,
  TitleForm,
} from "./YourDream.styles";
import { Range } from "react-range";

const YourDream = () => {
  const [value, setValue] = React.useState([5]);
  const handleSliderChange = (newValue: number[]) => {
    setValue(newValue);
  };
  return (
    <Background id="signup">
      <Container style={{ marginTop: "8rem" }}>
        <Content style={{ width: "65%" }}>
          <div style={{ width: "80%" }}>
            <Caption>Candidate-se</Caption>
            <Title>
              Seu sonho é o <strong>início</strong> de grandes{" "}
              <strong>conquistas</strong>
            </Title>
            <Description>
              Após preencher o formulário nossa equipe irá entrar em contato{" "}
              <br />
              para dar prosseguimento a sua aplicação.
            </Description>
          </div>
          <Image src="/img/Banner_Formulario.png" />
        </Content>
        <Content style={{ width: "35%" }}>
          <Form>
            <TitleForm>Formulário de aplicação IMME</TitleForm>
            <TextField name="nome" label="Nome *" />
            <TextField name="email" label="Email *" />
            <TextField name="whatsapp" label="Whatsapp com DDD *" />
            <SelectField name="escolaridade" label="Qual sua escolaridade?">
              <option value="">Selecione</option>
              <option value="Pós Doutorado">Pós Doutorado</option>
              <option value="Doutorado">Doutorado</option>
              <option value="Mestrado">Mestrado</option>
              <option value="Pós Graduado">Pós Graduado</option>
              <option value="Bacharelado">Bacharelado</option>
              <option value="Licenciatura">Licenciatura</option>
              <option value="Tecnólogo">Tecnólogo</option>
              <option value="Sem ensino superior">Sem ensino superior</option>
            </SelectField>
            <SelectField
              name="tempo_formado"
              label="Quanto tempo você tem de formado?"
            >
              <option value="">Selecione</option>
              <option value="Menos de 1 ano">Menos de 1 ano</option>
              <option value="De 1 a 3 anos">De 1 a 3 anos</option>
              <option value="De 3 a 5 anos">De 3 a 5 anos</option>
              <option value="De 5 a 7 anos">De 5 a 7 anos</option>
              <option value="De 7 a 10 anos">De 7 a 10 anos</option>
              <option value="Mais de 10 anos">Mais de 10 anos</option>
            </SelectField>

            <TextField name="tempo_profissao" label="Tempo de atuação *" />

            <TextField
              name="onde_graduacao"
              label="Onde fez/faz sua graduação? *"
            />

            <TextField
              name="onde_graduacao"
              label="Possui curso extracurricular? Qual? *"
            />

            <TextField
              name="onde_graduacao"
              label="Possui alguma especialização? *"
            />
            <br />

            <Label>
              <span>
                De Zero a 10 qual seria sua autoavaliação sobre sua fluência na
                língua inglesa?
              </span>
              <br />

              <br />

              <div style={{ display: "flex", justifyContent: "center" }}>
                <Range
                  step={1}
                  min={0}
                  max={10}
                  values={value}
                  onChange={(newValue) => handleSliderChange(newValue)}
                  renderTrack={({ props, children }) => (
                    <div
                      {...props}
                      style={{
                        ...props.style,
                        height: "6px",
                        width: "100%",
                        backgroundColor: "#ccc",
                        borderRadius: "4px",
                      }}
                    >
                      {children}
                    </div>
                  )}
                  renderThumb={({ props }) => (
                    <div
                      {...props}
                      style={{
                        ...props.style,
                        height: "20px",
                        width: "20px",
                        backgroundColor: "#fff",
                        borderRadius: "50%",
                        boxShadow: "0px 2px 6px #AAA",
                      }}
                    />
                  )}
                />
                <output
                  style={{
                    fontWeight: "bold",
                    marginLeft: "10px",
                    marginTop: "-5px",
                  }}
                >
                  {value[0]}
                </output>
              </div>
            </Label>
            <br />
            <br />

            <Button>Falar com especialista</Button>
          </Form>
        </Content>
      </Container>
    </Background>
  );
};

export default YourDream;
