import styled from "styled-components";
import { UIColors } from "../../styles/UIColors";
import { Theme } from "../../theme/Theme";

export const Background = styled.div`
  background-color: ${UIColors.BEGE_CLARO};
`;

export const Container = styled.section`
  display: flex;
  max-width: ${Theme.SPACING.CONTAINER}px;
  height: auto;
  width: 100%;
  margin: 0 auto;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  position: relative;
  flex: 1;
  @media screen and (max-width: 600px) {
    flex-direction: column;
    margin-bottom: 4em;
    margin-top: 4em;
    padding-right: 15px;
    padding-left: 15px;
    justify-content: unset;
  }
`;

export const Content = styled.div`
  width: 50%;
  padding-bottom: 100px;
  @media screen and (max-width: 600px) {
    width: 100%;
    padding-left: 0px !important;
  }
`;

export const Title = styled.h1`
  font-size: 48px;
  color: ${UIColors.BEGE_ESCURO};
  strong {
    color: ${UIColors.VERMELHO_ESCURO};
  }
  @media screen and (max-width: 600px) {
    font-size: 40px;
  }
`;

export const Description = styled.h3`
  margin-top: 20px;
  font-size: 14px;
  color: ${UIColors.BEGE_ESCURO};
  font-family: "Open Sans", sans-serif;
  font-weight: normal;
  line-height: 1.5;
`;

export const Image = styled.img`
  width: 100%;
  border-radius: 18px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
`;

export const Button = styled.button`
  width: 100%;
  padding: 20px;
  text-transform: uppercase;
  border-radius: 5px;
  font-weight: normal;
  background-color: ${UIColors.VERMELHO_ESCURO};
  color: #fff;
  font-weight: bold;
  margin-top: 25px;

  cursor: pointer;

  -webkit-box-shadow: 15px 8px 86px -19px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 15px 8px 86px -19px rgba(0, 0, 0, 0.75);
  box-shadow: 15px 8px 86px -19px rgba(0, 0, 0, 0.75);

  &:hover {
    background-color: ${UIColors.VERMELHO_HOVER};
  }
  &:active {
    background-color: ${UIColors.VERMELHO_FOCUS};
  }
`;
