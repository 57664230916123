import * as React from "react";
import { FaInstagram, FaYoutube } from "react-icons/fa";
import { UIColors } from "../../styles/UIColors";
import { Background, Button, Container, Menu } from "./Header.styles";

const Header = () => {
  return (
    <Background>
      <Container>
        <div>
          <Menu>
            <ul>
              <span style={{ marginRight: 15, color: UIColors.BEGE_ESCURO }}>
                Redes sociais:{" "}
              </span>
              <li>
                <a href="/">
                  <FaInstagram />
                </a>
              </li>
              <li>
                <a href="/">
                  <FaYoutube />
                </a>
              </li>
            </ul>
          </Menu>
        </div>
        <div>
          <h3 style={{ color: UIColors.BEGE_ESCURO }}>
            Seja{" "}
            <span style={{ color: UIColors.VERMELHO_ESCURO }}>
              bem-vindo(a)
            </span>
          </h3>
        </div>
        <div>
          <div>
            <a href="#board">
              <Button>Conheça nossa diretoria</Button>
            </a>
          </div>
        </div>
      </Container>
    </Background>
  );
};

export default Header;
