import React from "react";
import { FaWhatsapp } from "react-icons/fa";
import { Config } from "../../Configs";
import { WhatsAppBtn } from "./WhatsappButton.styles";

const WhatsappButton = () => {
  const navigate = () => {
    window.open(`https://wa.me/${Config.WHATSAPP}`, "_blank");
  };
  return (
    <WhatsAppBtn onClick={navigate}>
      <FaWhatsapp style={{ marginTop: "16px" }} />
    </WhatsAppBtn>
  );
};

export default WhatsappButton;
