import * as React from "react";
import { Label, SelectInput } from "./SelectField.styles";

interface OptionsType {
  value: string;
  label: string;
}

interface SelectFieldProps {
  label: React.ReactNode;
  name: string;
  children: React.ReactNode;
}

const SelectField = ({ label, name, children, ...props }: SelectFieldProps) => {
  return (
    <>
      <Label>
        <span>{label}</span>
        <SelectInput id={name} name={name} {...props}>
          {children}
        </SelectInput>
      </Label>
    </>
  );
};

export default SelectField;
