import React from "react";

import { Background, Container } from "./styles";
import Menu from "./Menu";

const Footer: React.FC = () => {
  return (
    <Background style={{ marginTop: 100 }}>
      <Container  style={{ marginTop: 50, marginBottom: 50 }}>
        <Menu />
      </Container>
    </Background>
  );
};

export default Footer;
