import * as React from "react";
import {
  Card,
  Container,
  ContainerGrid,
  Content,
  DescriptionCard,
  Details,
  ImageWork,
  Title,
  TitleCard,
} from "./HowItWorks.styles";

import Background from "../../assets/images/howItWorks/background.png";
import { UIColors } from "../../styles/UIColors";
const HowItWorks = () => {
  return (
    <Container id="howToWork">
      <ContainerGrid style={{ width: "60%" }}>
        <ImageWork src={Background} alt="How it Works Img" />
      </ContainerGrid>
      <ContainerGrid style={{ width: "40%" }}>
        <Details>
          <Title>
            <strong>Como funciona</strong> <br />o processo?
          </Title>
          <Content style={{ marginTop: 30 }}>
            <Card>
              <TitleCard>Atendimento</TitleCard>
              <DescriptionCard>
                Após seu cadastro, nossa equipe irá agendar uma consulta para
                conhecer melhor o seu perfil, seus objetivos e esclarecer
                dúvidas. Desta forma, avaliamos suas reais possibilidades de
                morar e trabalhar nos Estados Unidos.
              </DescriptionCard>
            </Card>
            <Card>
              <TitleCard>
                Estudo do{" "}
                <span style={{ color: UIColors.VERMELHO_ESCURO }}>
                  perfil do cliente
                </span>
              </TitleCard>
              <DescriptionCard>
                A segunda etapa é a realização de um estudo minucioso do seu
                perfil, identificando todas as possibilidades e necessidades
                para as próximas etapas do processo de migração antes de morar
                nos Estados Unidos.
              </DescriptionCard>
            </Card>
            <Card>
              <TitleCard>
                Seleção dos{" "}
                <span style={{ color: UIColors.VERMELHO_ESCURO }}>
                  candidatos
                </span>
              </TitleCard>
              <DescriptionCard>
                Não possuímos número limitante de vagas disponíveis, mas
                precisamos fazer a triagem dos profissionais que estão aptos a
                aplicar e conseguir atuar com a enfermagem nos EUA.
              </DescriptionCard>
            </Card>
            <Card>
              <TitleCard>
                Apresentação do{" "}
                <span style={{ color: UIColors.VERMELHO_ESCURO }}>projeto</span>
              </TitleCard>
              <DescriptionCard>
                Nessa fase detalhamos os próximos passos de forma clara,
                específica e transparente. Definiremos um roteiro de trabalho
                até a concretização da ida para os EUA.
              </DescriptionCard>
            </Card>
            <Card>
              <TitleCard>
                Execução do{" "}
                <span style={{ color: UIColors.VERMELHO_ESCURO }}>projeto</span>
              </TitleCard>
              <DescriptionCard>
                Com os próximos passos definidos, nossos especialistas continuam
                acompanhando você durante o restante do processo, que envolve
                etapas importantes até a sua mudança para os Estados Unidos.
              </DescriptionCard>
            </Card>
          </Content>
        </Details>
      </ContainerGrid>
    </Container>
  );
};

export default HowItWorks;
