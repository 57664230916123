import React from "react";
import {   FaYoutube, FaInstagram } from "react-icons/fa";

import { Container, Menu } from "./styles";
import Logo from "../../../assets/images/logo/logo-red.svg";

import { Background } from "../styles";

const Header: React.FC = () => {
  return (
    <Background>
      <Container>
        <div>
          <a href="/">
            <img width='50%' src={Logo} alt="Covid-19" />
          </a>
          <Menu>
            <ul>
              <li>
                <a href="#home" className="active">
                  Inicio
                </a>
              </li>
              <li>
                <a href="#about">Quem somos</a>
              </li>
              <li>
                <a href="#howToWork">Como funciona?</a>
              </li>
           
              <li>
                <a href="#signup">Candidatar-se</a>
              </li>
            </ul>
          </Menu>
          <Menu>
            <ul>
              <li>
                <a href="/">
                  <FaInstagram />
                </a>
              </li>
              <li>
                <a href="/">
                  <FaYoutube />
                </a>
              </li>
            </ul>
          </Menu>
        </div>
      </Container>
    </Background>
  );
};

export default Header;
