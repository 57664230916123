import styled from "styled-components";
import { UIColors } from "../../styles/UIColors";
import { Theme } from "../../theme/Theme";

export const Container = styled.section`
  display: flex;
  max-width: ${Theme.SPACING.CONTAINER}px;
  width: 100%;
  margin: 0 auto;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  position: relative;
  flex: 1;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    margin-bottom: 4em;
    margin-top: 4em;
    padding-right: 15px;
    padding-left: 15px;
    justify-content: unset;
  }
`;

export const Title = styled.h1`
  font-size: 48px;
  margin-top: 20px;
  color: ${UIColors.VERMELHO_ESCURO};

  @media screen and (max-width: 600px) {
    font-size: 40px;
  }
`;

export const Description = styled.h3`
  margin-top: 20px;
  font-family: "Open Sans", sans-serif;
  font-weight: normal;
  line-height: 1.5;
  font-size: 14px;
`;

export const Grid = styled.div`
  text-align: center;
  margin-bottom: 50px;
`;
export const Content = styled.div``;

export const ImageContainer = styled.div`
  padding: 10px;
  border-radius: 200px;
  border: 1px solid ${UIColors.BEGE_CLARO};
  margin-bottom: 10px;
  width: 200px;
  height: 200px;
`;

export const Image = styled.img`
  max-width: 180px;
  border-radius: 200px;
`;

export const UserContainer = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
`;

export const UserName = styled.h1`
  color: ${UIColors.BEGE_ESCURO};
`;
export const UserWork = styled.h3`
  color: ${UIColors.VERMELHO_ESCURO};
  font-size: 14px;
  margin-top: 0px;
`;
export const UserUrl = styled.h4`
  color: ${UIColors.BEGE_ESCURO};
  font-size: 14px;
  margin-top: 0px;
`;

export const Button = styled.button`
  text-align: center;
  padding: 10px;
  font-size: 14px;
  border-radius: 100px;
  margin-top: 20px;
  color: white;
  font-weight: 400;
  letter-spacing: 2px;
  background-color: ${UIColors.BEGE_ESCURO};
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;

  -webkit-box-shadow: 15px 8px 86px -19px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 15px 8px 86px -19px rgba(0, 0, 0, 0.75);
  box-shadow: 15px 8px 86px -19px rgba(0, 0, 0, 0.75);

  &:hover {
    background-color: ${UIColors.VERMELHO_HOVER};
  }
  &:active {
    background-color: ${UIColors.VERMELHO_FOCUS};
  }
`;
