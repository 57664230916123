import React from "react";

import Header from "./components/Header/Header";
import About from "./sections/About/About";
import Hero from "./sections/Hero/Hero";
import HowItWorks from "./sections/HowItWorks/HowItWorks";

import "slick-carousel/slick/slick.css";
import "./styles/globals.css";
import "./styles/react-slick.css";
// import Cases from "./sections/Cases/Cases";
import WhySelect from "./sections/WhySelect/WhySelect";
import YourDream from "./sections/YourDream/YourDream";
// import Video from "./sections/Video/Video";
import Footer from "./sections/Footer";
import Board from "./sections/Board/Board";
import WhatsappButton from "./components/WhatsappButton/WhatsappButton";
import ScrollToTopButton from "./components/ScrollToTopButton/ScrollToTopButton";
import Menu from "./components/Menu/Menu";
import useMobile from "./hooks/useMobile";

function App() {
  const isMobile = useMobile();
  const [showButton, setShowButton] = React.useState(false);
  React.useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 200) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {!isMobile && showButton && <Menu />}
      <Header />
      <Hero />
      <About />
      <HowItWorks />
      {/* <Cases /> */}
      <WhySelect />
      <YourDream />
      {/* <Video /> */}
      <Board />
      <Footer />
      {showButton && <ScrollToTopButton />}
      <WhatsappButton />
    </>
  );
}

export default App;
